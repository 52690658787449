import React, { useState, useCallback } from "react";
import * as AuthHelpers from "../../common/helpers/auth";
import { withRouter } from "react-router-dom";
import Cookies from "js-cookie";

export const AuthContext = React.createContext();

const AuthProvider = (props) => {
  const login = useCallback(() => {
    var redirect = `${window.location.origin}/loginredirect`;
    var appClientId = process.env.REACT_APP_App_Client_Id;
    var tenantId = "0b332d8d-523e-4b4b-91f6-e49412182daf";
    const loginURL = `https://login.microsoftonline.com/${tenantId}/oauth2/v2.0/authorize?response_type=token&scope=api://${appClientId}/FullAccess&client_id=${appClientId}&redirect_uri=${redirect}&prompt=select_account`;
    window.location.href = loginURL;
  }, []);

  const redirect = useCallback(() => {
    let redirectUrl;
    const localRedirectUrl = window.localStorage.getItem("redirect-url");

    if (localRedirectUrl && localRedirectUrl !== "/") {
      redirectUrl = localRedirectUrl;
    } else {
      redirectUrl = "/deployments";
    }

    props.history.push(redirectUrl);
  }, [props.history]);

  const logout = useCallback(() => {
    const logoutURL = `https://login.microsoftonline.com/common/oauth2/v2.0/logout?post_logout_redirect_uri=${encodeURIComponent(
      window.location.protocol + "//" + window.location.hostname
    )}`;

    setLoggedOut(true);

    Cookies.remove("token");

    localStorage.clear();
    sessionStorage.clear();
    setUser(null);
    window.location.href = logoutURL;
  }, []);

  const doLogin = useCallback(
    (authToken) => {
      Cookies.set("token", authToken);
      setUser(AuthHelpers.buildUser());
      redirect();
    },
    [redirect]
  );

  const userObject = AuthHelpers.buildUser();
  const [user, setUser] = useState(userObject ? userObject : null);

  const [loggedOut, setLoggedOut] = useState(false);

  const value = React.useMemo(() => {
    return {
      redirect,
      user,
      login,
      logout,
      doLogin,
      loggedOut,
    };
  }, [redirect, user, doLogin, login, logout, loggedOut]);

  return (
    <AuthContext.Provider value={value}>{props.children}</AuthContext.Provider>
  );
};

const AuthProviderWithRouter = withRouter(AuthProvider);
export default AuthProviderWithRouter;
