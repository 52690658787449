import React from "react";
import Card from "../../Card";
import DeploymentCreateEditForm from "../../Forms/DeploymentCreateEditForm";
import SplashLoader from "../../Loaders/SplashLoader";
import useProductManagementApi from "../../../api/useProductManagementApi";
import ErrorMessages from "../../Notifications/ErrorMessages";

export default (props) => {
  const deploymentId =
    props &&
    props.match &&
    props.match.params &&
    props.match.params.deploymentId;

  const [
    {
      loading: getSubscriptionLoading,
      errors: getSubscriptionError,
      data: getSubscriptionData,
    },
  ] = useProductManagementApi("/subscription", "get", null);

  const [
    {
      loading: getLocationLoading,
      errors: getLocationError,
      data: getLocationData,
    },
  ] = useProductManagementApi("/deploymentLocation", "get", null);

  if (getSubscriptionLoading || getLocationLoading) {
    const loadingMsg = getSubscriptionLoading
      ? "Fetching Subscriptions"
      : "Fetching Locations";
    return <SplashLoader text={loadingMsg} />;
  }

  if (getSubscriptionError)
    return <ErrorMessages errors={getSubscriptionError} />;
  if (getLocationError) return <ErrorMessages errors={getLocationError} />;

  // Format exiting entities to be able to be used by UI
  let subscriptions = [];

  if (getSubscriptionData) {
    subscriptions = getSubscriptionData.map((s) => {
      return { value: s.id, label: s.name };
    });
  }

  let locations = [];

  if (getLocationData) {
    locations = getLocationData.map((l) => {
      return { value: l.id, label: l.name };
    });
  }

  // Body Section of View
  function Body() {
    return (
      <DeploymentCreateEditForm
        entityId={deploymentId}
        entityName={"Deployment"}
        entityBaseUrl={"deployment"}
        subscriptions={subscriptions}
        locations={locations}
        {...props}
      />
    );
  }

  return (
    <Card
      title={(deploymentId ? "Edit" : "Add") + " Deployment"}
      titleDescription={(deploymentId ? "Edit" : "Add") + " Deployment"}
      body={Body}
    />
  );
};
