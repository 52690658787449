import React, { useState, useEffect } from "react";
import useProductManagementApi from "../../api/useProductManagementApi";
import Spinner from "../Loaders/Spinner";
import ErrorMessages from "../Notifications/ErrorMessages";
import { Formik, Form } from "formik";
import {
  FormControl,
  StyledField,
  FormActions,
  ErrorMessage,
  StyledSelect,
  Label,
} from "./FormControls";
import Button from "../Button";
import SplashLoader from "../Loaders/SplashLoader";
import confirmDlg from "./ConfirmDialog.js";

export default (props) => {
  const {
    entityId,
    entityName,
    entityBaseUrl,
    subscriptions,
    locations,
  } = props;
  const defaultId = "System Generated";

  const [confirmDlgConfig, setConfirmDlgConfig] = useState({
    show: false,
    title: "",
    description: "",
    action: null,
  });

  // Setup needed API Hooks
  // If entity ID is provided get entity
  const url = entityId ? `/${entityBaseUrl}/${entityId}` : null;
  const [
    { loading: getLoading, errors: getError, data: getData },
  ] = useProductManagementApi(url, "get", null);

  // Used to create a new one
  const [
    { loading: createLoading, errors: createError, data: createData },
    createApi,
  ] = useProductManagementApi();

  // Used to delete an existing one
  const [
    { loading: deleteLoading, errors: deleteError, data: deleteData },
    deleteApi,
  ] = useProductManagementApi();

  // Used to edit an existing one
  const [
    { loading: updateLoading, errors: updateError, data: updateData },
    updateApi,
  ] = useProductManagementApi();

  useEffect(() => {
    // Return to list view if one was deleted, edited or created
    if (deleteData || updateData || createData) {
      props.history.push(`/${entityBaseUrl}s`);
    }
  }, [deleteData, updateData, createData, entityBaseUrl]); // eslint-disable-line react-hooks/exhaustive-deps

  if (
    entityId &&
    (getLoading || createLoading || deleteLoading || updateLoading)
  ) {
    const loadingMsg = getLoading
      ? `Fetching ${entityName}`
      : deleteLoading
      ? `Deleting ${entityName}`
      : updateLoading
      ? `Updating ${entityName}`
      : `Creating ${entityName}`;
    return <SplashLoader text={loadingMsg} />;
  }

  return (
    <>
      {confirmDlg(confirmDlgConfig, setConfirmDlgConfig)}

      <Formik
        enableReinitialize={true}
        initialValues={{
          id: getData ? getData.id : defaultId,
          name: getData ? getData.name : "",
          url: getData ? getData.url : "",
          location:
            getData && locations
              ? locations.find((l) => l.value === getData.locationId)
              : "",
          subscription:
            getData && subscriptions
              ? subscriptions.find((s) => s.value === getData.subscriptionId)
              : "",
        }}
        validate={(values) => {
          let errors = {};

          if (!values.name) {
            errors.name = "Required";
          }

          if (!values.url) {
            errors.url = "Required";
          }

          if (!values.location) {
            errors.location = "Required";
          }

          if (!values.subscription) {
            errors.subscription = "Required";
          }

          return errors;
        }}
        onSubmit={(values) => {
          if (values.id === defaultId) {
            createApi({
              url: `/${entityBaseUrl}`,
              method: "post",
              body: {
                name: values.name,
                url: values.url,
                LocationId:
                  values.location && values.location.value
                    ? values.location.value
                    : null,
                SubscriptionId:
                  values.subscription && values.subscription.value
                    ? values.subscription.value
                    : null,
              },
            });
          } else {
            updateApi({
              url: `/${entityBaseUrl}/${values.id}`,
              method: "put",
              body: {
                name: values.name !== getData.name ? values.name : null,
                url: values.url,
                LocationId:
                  values.location && values.location.value
                    ? values.location.value
                    : null,
                SubscriptionId:
                  values.subscription && values.subscription.value
                    ? values.subscription.value
                    : null,
              },
            });
          }
        }}
      >
        {({
          values,
          isSubmitting,
          isValid,
          setFieldValue,
          setFieldTouched,
        }) => {
          return (
            <Form>
              {entityId && (
                <FormControl>
                  <StyledField
                    name={`id`}
                    type="text"
                    label="ID"
                    value={values.id}
                    disabled={true}
                  />
                  <ErrorMessage name={`id`} />
                </FormControl>
              )}
              <FormControl>
                <StyledField
                  name={`name`}
                  type="text"
                  label="Name"
                  placeholder="Name"
                  value={values.name}
                />
                <ErrorMessage name={`name`} />
              </FormControl>
              <FormControl>
                <StyledField
                  name={`url`}
                  type="text"
                  label="URL"
                  placeholder="URL"
                  value={values.url}
                />
                <ErrorMessage name={`url`} />
              </FormControl>
              <FormControl>
                <Label>Subscription</Label>
                <StyledSelect
                  className={`react-select-container`}
                  classNamePrefix={`react-select`}
                  name={`subscription`}
                  placeholder={`Subscription`}
                  label="Subscription"
                  value={values.subscription}
                  options={subscriptions}
                  onBlur={(e) => setFieldTouched(`subscription`, e)}
                  onChange={(e) => setFieldValue(`subscription`, e)}
                  menuPortalTarget={document.body}
                />
                <ErrorMessage name={`subscription`} />
              </FormControl>
              <FormControl>
                <Label>Location</Label>
                <StyledSelect
                  className={`react-select-container`}
                  classNamePrefix={`react-select`}
                  name={`location`}
                  placeholder={`Location`}
                  label="Location"
                  value={values.location}
                  options={locations}
                  onBlur={(e) => setFieldTouched(`location`, e)}
                  onChange={(e) => setFieldValue(`location`, e)}
                  menuPortalTarget={document.body}
                />
                <ErrorMessage name={`location`} />
              </FormControl>
              <FormActions>
                <FormControl>
                  {getError ||
                  deleteError ||
                  createError ||
                  updateError ||
                  updateError ? (
                    <ErrorMessages
                      errors={
                        getError
                          ? getError
                          : deleteError
                          ? deleteError
                          : createError
                          ? createError
                          : updateError
                      }
                    />
                  ) : null}

                  {entityId && (
                    <Button
                      list="true"
                      danger="true"
                      type="button"
                      onClick={() => {
                        setConfirmDlgConfig({
                          show: true,
                          title: `Confirm ${entityName} Removal`,
                          description: `Are you sure you wish to remove this ${entityName}?`,
                          action: () => {
                            deleteApi({
                              url: url,
                              method: "delete",
                              body: null,
                            });
                          },
                        });
                      }}
                    >
                      Remove
                    </Button>
                  )}

                  <Button list="true" danger="true" type="reset">
                    {entityId ? "Undo" : "Clear"}
                  </Button>

                  <Button
                    type="submit"
                    disabled={
                      (isSubmitting &&
                        !getError &&
                        !deleteError &&
                        !createError &&
                        !updateError) ||
                      !isValid
                    }
                  >
                    Submit
                  </Button>
                </FormControl>

                {isSubmitting &&
                !getError &&
                !deleteError &&
                !createError &&
                !updateError ? (
                  <FormControl>
                    <Spinner />
                  </FormControl>
                ) : null}
              </FormActions>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};
