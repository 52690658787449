import React from "react";
import Card from "../../Card";
import SubscriptionCreateEditForm from "../../Forms/SubscriptionCreateEditForm";
import SplashLoader from "../../Loaders/SplashLoader";
import useProductManagementApi from "../../../api/useProductManagementApi";
import ErrorMessages from "../../Notifications/ErrorMessages";

export default (props) => {
  const subscriptionId =
    props &&
    props.match &&
    props.match.params &&
    props.match.params.subscriptionId;

  // Get needed exiting entities
  const [
    {
      loading: getCustomerLoading,
      errors: getCustomerError,
      data: getCustomerData,
    },
  ] = useProductManagementApi("/customer", "get", null);

  const [
    {
      loading: getSubscriptionTemplateLoading,
      errors: getSubscriptionTemplateError,
      data: getSubscriptionTemplateData,
    },
  ] = useProductManagementApi("/subscriptiontemplate", "get", null);

  if (getCustomerLoading) {
    return <SplashLoader text={"Fetching Customers"} />;
  }

  if (getCustomerLoading || getSubscriptionTemplateLoading) {
    const loadingMsg = getCustomerLoading
      ? `Fetching Customers`
      : `Fetching Subscription Templates`;
    return <SplashLoader text={loadingMsg} />;
  }

  if (getCustomerError) return <ErrorMessages errors={getCustomerError} />;
  if (getSubscriptionTemplateError)
    return <ErrorMessages errors={getSubscriptionTemplateError} />;

  let customers = [];

  if (getCustomerData) {
    customers = getCustomerData.map((c) => {
      return { value: c.id, label: c.name };
    });
  }

  let subscriptionTemplates = [];

  if (getSubscriptionTemplateData) {
    subscriptionTemplates = getSubscriptionTemplateData.map((s) => {
      return { value: s.id, label: s.name };
    });
  }

  // Body Section of View
  function Body() {
    return (
      <SubscriptionCreateEditForm
        entityId={subscriptionId}
        entityName={"Subscription"}
        entityBaseUrl={"subscription"}
        customers={customers}
        subscriptionTemplates={subscriptionTemplates}
        {...props}
      />
    );
  }

  return (
    <Card
      title={(subscriptionId ? "Edit" : "Add") + " Subscription"}
      titleDescription={(subscriptionId ? "Edit" : "Add") + " Subscription"}
      body={Body}
    />
  );
};
