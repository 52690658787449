import React, { useState } from "react";
import Card from "../../Card";
import Table from "../../Table";
import Spinner from "../../Loaders/Spinner";
import ErrorMessages from "../../Notifications/ErrorMessages";
import { MdAdd, MdRefresh } from "react-icons/md";
import ActionsWrapper from "./ActionsWrapper";
import ActionsLink from "./ActionsLink";
import useProductManagementApi from "../../../api/useProductManagementApi";
import "react-table/react-table.css";

export default ({
  listType,
  listTypeCommand,
  columnsData,
  includeActiveUI,
}) => {
  const createCommand = `/${listTypeCommand}/create`;
  const getCommand = `/${listTypeCommand}`;

  // Body Section of Widget
  function Body() {
    const [includeInactive, setIncludeInactive] = useState(true);

    const [{ loading, errors, data }] = useProductManagementApi(
      getCommand,
      "get",
      null
    );

    if (loading) return <Spinner />;
    if (errors) return <ErrorMessages errors={errors} />;

    let filteredData = data;
    if (includeActiveUI && !includeInactive && data) {
      filteredData = data.filter((d) => d.active === true);
    }

    return (
      <>
        <Table data={filteredData} columnsData={columnsData} />
        <br />
        {includeActiveUI ? (
          <label>
            <input
              type="checkbox"
              checked={includeInactive}
              onChange={() => setIncludeInactive(!includeInactive)}
            />
            Include Inactive
          </label>
        ) : null}
      </>
    );
  }

  // Actions Section of Widget
  function Actions() {
    return (
      <>
        <ActionsWrapper>
          <ActionsLink to={createCommand} title={`Add New ${listType}`}>
            <MdAdd />
          </ActionsLink>
        </ActionsWrapper>
        <ActionsWrapper>
          <ActionsLink to={"#"} title={`Refresh Available ${listType}s`}>
            <MdRefresh />
          </ActionsLink>
        </ActionsWrapper>
      </>
    );
  }

  return (
    <Card
      title={`${listType}s`}
      titleDescription={`Available ${listType}s`}
      body={Body}
      actions={Actions}
    />
  );
};
