import React, { useContext } from "react";
import { withRouter } from "react-router-dom";
import { FlexVertCenterContainer } from "../Layout/Flex/FlexContainers";
import styled from "styled-components";
import { AuthContext } from "./AuthContext";
import image from "../../assets/images/bluecity.jpg";

const Container = styled.div`
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  z-index: -100;
  height: 100%;
  background: url(${image}) no-repeat center center fixed;
`;

const LoginError = styled.div`
  margin-top: 20px;
`;

const MarketingContainer = styled.div`
  border: 4px solid #fff;
  max-width: 1080px;
  padding: 8px;
  color: #fff;
`;

const TagLineH3 = styled.h3`
  margin: 0;
  padding: 0;
  font-size: 40px;
  font-weight: bold;
  text-align: center;
  @media (max-width: 768px) {
    font-size: 31px;
  }
  @media (max-width: 593px) {
    font-size: 17px;
    text-align: center;
    margin-bottom: 5px;
  }
`;

const TagLine = styled.h3`
  margin: 0;
  letter-spacing: 1px;
  text-align: center;
  font-size: 31px;
  @media (max-width: 768px) {
    font-size: 23px;
  }
  @media (max-width: 593px) {
    font-size: 11px;
  }
`;

const LoginBtn = styled.div`
  padding: 20px;
  padding-right: 40px;
  padding-left: 40px;
  margin-top: 50px;
  color: #ddd;
  background: #121135;
  box-shadow: 2px 3px 5px 0px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  font-weight: bold;
`;

const LoginText = styled.span`
  padding-bottom: 1px;
  padding-left: 4px;
  padding-right: 4px;
`;

const Login = () => {
  const { login, loginError } = useContext(AuthContext);
  return (
    <Container>
      <FlexVertCenterContainer col flex>
        <MarketingContainer>
          <TagLineH3>PRODUCT MANAGEMENT TOOL</TagLineH3>
          <TagLine>USED TO MANAGE DEPLOYMENTS</TagLine>
        </MarketingContainer>
        <LoginBtn onClick={login}>
          <LoginText>{"LOGIN"}</LoginText>
        </LoginBtn>
        <LoginError>{loginError ? loginError : null}</LoginError>
      </FlexVertCenterContainer>
    </Container>
  );
};

export default withRouter(Login);
