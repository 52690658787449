import React, { Component } from "react";
import { Switch, withRouter } from "react-router-dom";
import ProtectedRoute from "../components/Auth/ProtectedRoute";
import DeploymentListView from "../components/Views/ListViews/DeploymentListView";
import DeployementFormView from "../components/Views/FormViews/DeploymentFormView";
import SubscriptionListView from "../components/Views/ListViews/SubscriptionListView";
import SubscriptionFormView from "../components/Views/FormViews/SubscriptionFormView";
import SubscriptionTemplateListView from "../components/Views/ListViews/SubscriptionTemplateListView";
import SubscriptionTemplateFormView from "../components/Views/FormViews/SubscriptionTemplateFormView";
import CustomerListView from "../components/Views/ListViews/CustomerListView";
import CustomerFormView from "../components/Views/FormViews/CustomerFormView";
import DeployementLocationListView from "../components/Views/ListViews/DeploymentLocationListView";
import DeployementLocationFormView from "../components/Views/FormViews/DeploymentLocationFormView";
import LoginRedirectRoute from "../components/Auth/LoginRedirect";
import * as paths from "../common/paths";

class ContentPage extends Component {
  render() {
    return (
      <Switch>
        <LoginRedirectRoute path="/loginredirect" />

        <ProtectedRoute
          path={paths.deployments()}
          component={DeploymentListView}
        />
        <ProtectedRoute
          path={paths.createDeployment()}
          component={DeployementFormView}
        />
        <ProtectedRoute
          path={paths.deploymentWithParam()}
          component={DeployementFormView}
        />

        <ProtectedRoute
          path="/subscriptions"
          component={SubscriptionListView}
        />
        <ProtectedRoute
          path="/subscription/create"
          component={SubscriptionFormView}
        />
        <ProtectedRoute
          path={paths.subscriptionWithParam()}
          component={SubscriptionFormView}
        />

        <ProtectedRoute
          path="/subscriptiontemplates"
          component={SubscriptionTemplateListView}
        />
        <ProtectedRoute
          path="/subscriptionTemplate/create"
          component={SubscriptionTemplateFormView}
        />
        <ProtectedRoute
          path={paths.subscriptionTemplateWithParam()}
          component={SubscriptionTemplateFormView}
        />

        <ProtectedRoute
          path="/deploymentLocations"
          component={DeployementLocationListView}
        />
        <ProtectedRoute
          path="/deploymentLocation/create"
          component={DeployementLocationFormView}
        />
        <ProtectedRoute
          path={paths.deploymentLocationWithParam()}
          component={DeployementLocationFormView}
        />

        <ProtectedRoute path="/customers" component={CustomerListView} />
        <ProtectedRoute path="/customer/create" component={CustomerFormView} />
        <ProtectedRoute
          path={paths.customerWithParam()}
          component={CustomerFormView}
        />

        <ProtectedRoute path="/" component={DeploymentListView} />
      </Switch>
    );
  }
}

export default withRouter(ContentPage);
